(function ($, document, window, viewport) {
  	$.fn.navPanes = function () {
		var openPane = false;
		var navButtons = $(this).find("a");
		var viewPortIsLgUp = viewport.is(">=lg");

		// 'Back' buttons
		$(".nav-pane__back").click(function () {
			$(navButtons).filter(".expanded").removeClass("expanded");
			var parentPane = $(this).parent(".nav-pane");
			hidePane(parentPane);
		});

		// Init nav buttons
		return navButtons.each(function () {
			var config = {
				elem: this,
				target: $(this).attr("href"),
			};

			// Setup handlers
			$(config.elem).click(function (e) {
				e.preventDefault();
				var selfClick = $(this).hasClass("expanded");
				hidePane($(".nav-pane:visible"));

				if (!selfClick) {
					$(".main-head__nav .expanded").removeClass("expanded");
					$("html").addClass("nav-active");
					$(".main-head").addClass("selected");
					$(this).addClass("expanded");

					if (!openPane) {
						revealPane(config.target);
					}
				} else {
					$("html").removeClass("nav-active");
					$(".main-head").removeClass("selected");
					$(this).removeClass("expanded");
				}
			});
		});

		// Hide a pane
		function hidePane(oldPane, newPane, callback) {
			// Separate behavior for small and large screens
			if (viewPortIsLgUp) {
				$(oldPane).hide();
			} else {
				var scrollPos = $(window).scrollTop();
				var navOffset = $(".main-head__nav").offset();
				var paneStyles = {
					display: "block",
					position: "fixed",
					top: navOffset.top - scrollPos,
					left: 0,
				};

				$(oldPane)
					.css(paneStyles)
					.stop()
					.animate({ left: "100%" }, function () {
						$(this).css("display", "none");
					});
			}

			// No more open panes
			openPane = false;

			// If we want to reveal a new pane after closing this one
			if (typeof callback === "function") {
				callback(newPane);
			}
		}

		// Reveal a pane
		function revealPane(newPane) {
			// Separate behavior for small and large screens
			if (viewPortIsLgUp) {
				var navHeight = $(".main-head__nav").outerHeight();
				var paneStyles = {
					display: "none",
					position: "absolute",
					top: navHeight + "px",
					left: 0,
				};

				$(newPane).css(paneStyles).fadeIn("fast");
			} else {
				var scrollPos = $(window).scrollTop();
				var navOffset = $(".main-head__nav").offset();
				var navPos = $(".main-head__nav").position();
				var paneStyles = {
					display: "block",
					position: "fixed",
					top: navOffset.top - scrollPos,
					left: "100%",
				};

				$(newPane)
					.css(paneStyles)
					.stop()
					.animate({ left: 0 }, function () {
						$(this).css({
						position: "absolute",
						top: navPos.top,
						});
					});
			}

			// There is an open pane
			openPane = true;

			//Record Marquee Impressions
			var newPaneSelector = "#" + $(newPane).prop("id") + " [data-mqk]:visible";
			$(newPaneSelector).each(function (index) {
				utag.link({
					event_action: "Impression",
					event_category: "Marquees",
					event_label:
						$(this).closest("div[data-mqs]").attr("data-mqs") +
						" : " +
						$(this).data("mqk"),
					ga_noninteraction: "1",
				});
			});
		}
	};
})(jQuery, document, window, ResponsiveBootstrapToolkit);
