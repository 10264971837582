//Google Tag Manager Event Firing
function GTMAddToCart(SKU, QTY) {
	//initialize global JavaScript variable: dataLayer
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(
    {
    	'event': 'add-to-cart',
    	'productSKU': SKU,
    	'quantity': QTY
    });
}

function GTMRemoveFromCart(SKU, QTY) {
	//initialize global JavaScript variable: dataLayer
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(
    {
    	'event': 'remove-from-cart',
    	'productId': SKU,
    	'quantity': QTY
    });
}

function GTMFireGAEvent(Category, Action, Label) {
	//initialize global JavaScript variable: dataLayer
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(
    {
    	"event": "fireGaEvent",
    	"eventCategory": Category,
    	"eventAction": Action,
    	"eventLabel": Label
    });
}

function GTMFirecheckoutStep(GTMProductList, GTMCheckoutStep) {
	//initialize global JavaScript variable: dataLayer
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(
    {
    	'event': 'checkout-step',
    	'ecommerce':
            {
            	'checkout':
					{
						'actionField':
							{
								'step': GTMCheckoutStep,
								'option': ''
							}, 'products': GTMProductList
					}
            }
    });
}

function GTMFireProductImpressionList(GTMProductImpressionList, ListName) {
	//initialize global JavaScript variable: dataLayer
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(
    {
    	'event': 'product-impression',
    	'productList': ListName,
    	'products': GTMProductImpressionList
    });
}

function GTMFireProductDetailView(GTMProductImpression, ViewType, ParentProductID) {
	//initialize global JavaScript variable: dataLayer
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(
    {
    	'event': 'product-details',
    	'viewFormat': ViewType, //Quick View or Full View
    	'parentID': ParentProductID,
    	'products': GTMProductImpression
    });
}

function GTMFireProductClick(ClickElement, ProductSKU) {
	//initialize global JavaScript variable: dataLayer
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(
    {
    	'event': 'product-click',
    	'clickElement': ClickElement,
    	'productSKU': ProductSKU
    });
}


var ACE = ACE || {};
ACE.TagManager = ACE.TagManager || {};

ACE.TagManager.FireUtagLink = function (eventData, callback) {
    if (window.utag !== undefined) {
        utag.link(eventData, callback);
    } else if (callback !== undefined) {
        try {
            callback();
        } catch (e) { console.log(e); }
    }
};

ACE.TagManager.ItemAddedToCart = function (addToCartResult, callback) {
	if (addToCartResult.TealiumData) {
		ACE.TagManager.FireUtagLink(addToCartResult.TealiumData, callback);
	}
	$.each(addToCartResult.AddedItems, function (index, value) {
		GTMAddToCart(value.SKU, value.Quantity); // Track the add
	});
};

ACE.TagManager.ItemQuantityUpdated = function (cartEvent, callback) {
	ACE.TagManager.FireUtagLink(cartEvent, callback);
};

ACE.TagManager.ItemRemovedFromCart = function (cartEvent, sku, quantity, callback) {
    GTMRemoveFromCart(sku, quantity);

    ACE.TagManager.FireUtagLink(cartEvent, callback);
};
