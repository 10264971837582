/* =====================================================
Adaptive Accordion:
Determines whether to display a bootstrap collapse as an accordion or not
based on viewport size.
uses: /plugins/bootstrap-toolkit.js
example usage on: certification/crt3.1.1-how-to-detail.html
====================================================== */
(function($, document, window, viewport) {

		$.fn.adapativeAccordion = function(options) {

				// Eject if there's an empty call
				if (!$(this).length) {
						return false;
				};

				var panels = this;

				var settings = $.extend({
						currentViewportContext: null,
						currentDisplayState: null
				}, options);

				function checkContext() {
						// if viewport is desktop size and panels are in mobile display state
						if (viewport.is(">=md") && settings.currentDisplayState === 'mobile') {
								// change to desktop view
								setDisplay('desktop');
						}
						// if viewport is mobile size and panels are in desktop display state
						if (viewport.is("<md") && settings.currentDisplayState === 'desktop') {
								// change to mobile view
								setDisplay('mobile');
						}
						// otherwise the viewport change is still within the
						// current display state boundries so there's noting to do
				}

				function setDisplay(type) {
						if (type === 'desktop') {
								// expand the content to display as cubes
								panels.collapse('show');
								// update settings
								settings.currentDisplayState = type
						}
						if (type === 'mobile') {
								// hide the panel nav and show accordion
								panels.collapse('hide');
								// update settings
								settings.currentDisplayState = type;
						}
						// update settings
						settings.currentViewportContext = viewport.current();
				}

				function initializeAdaptiveAccordion() {
						if (viewport.is(">=md")) {
								panels.collapse('show');
								settings.currentDisplayState = "desktop"
						} else {
								panels.collapse('hide');
								settings.currentDisplayState = "mobile";
						}

				};

				$(window).resize(viewport.changed(function() {
						// if the current viewport is different from the current setting
						if (viewport.current() !== settings.currentViewportContext) {
								// var vpWas = settings.currentViewportContext;
								// var vpNow = viewport.current();
								// console.log("viewport changed from " + vpWas + " to " + vpNow)
								// settings.currentViewportContext = vpNow;
								// check the new context to see if the display needs to change
								checkContext();
						}
				}, 150));

				// Intialize it on the element
				initializeAdaptiveAccordion();

		}

})(jQuery, document, window, ResponsiveBootstrapToolkit);
