$.fn.stickIt = function(params){
	return this.each(function(){
		var config = {
			elem: this,
			elemPos: $(this).offset().top
		};

		if (typeof params !== 'undefined'){
			config.offsetPos = params.offsetBy.outerHeight();
		} else {
			config.offsetPos = 0;
		}

		// Check on initialization
		checkPos(config);

		// Check on page scroll
		$(window).on('scroll', function(){
			checkPos(config);
		});

		// Check on window resize
		$(window).resize(function(){
			checkPos(config);
		});
	});

	function checkPos(config){
		var scrollPos = $(window).scrollTop();

		if(scrollPos > config.elemPos - config.offsetPos){
			$(config.elem).addClass('sticky').css({top: config.offsetPos});
		} else {
			$(config.elem).removeClass('sticky').css({top: 'auto'});
		}
	}
};
