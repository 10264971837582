var ACE = ACE || {};
ACE.TagManager = ACE.TagManager || {};

$(function () {

    ACE.TagManager.LoadProductAttributeImpressions = function (selector) {
        var startObj = selector;
        if (selector != undefined) {
            if (!selector instanceof jQuery) {
                startObj = $(selector);
            }
        } else {
            startObj = $('body');
        }
        var productGroups = [];
        startObj.find("[data-product_impressiongroup]")
			.addBack("[data-product_impressiongroup]")
			.each(function (i, e) {
			    var jElement = $(this);
			    var groupName = jElement.data('product_impressiongroup');
			    var foundGroup = $.grep(productGroups, function (g, i) { return g.name === groupName; });
			    var productGroup = null;
			    if (foundGroup.length == 1) {
			        productGroup = foundGroup[0];
			    } else {
			        productGroup = { name: groupName, products: [] };
			        productGroups.push(productGroup);
			    }
			    jElement.find("[data-product_sku]")
					.addBack("[data-product_sku]")
					.each(function (ci, ce) {
					    var product = $(this);
					    var impression = {
					        id: product.data('product_id'),
					        sku: product.data('product_sku'),
					        name: product.data('product_name'),
					        category: product.data('product_category'),
					        variant: parseInt(product.data('product_variant')),
					        price: parseFloat(product.data('product_price')),
					        quantity: parseInt(product.data('product_quantity')),
					        position: ci + 1
					    };
					    productGroup.products.push(impression);
					});
			});
        $.each(productGroups, function (i, p) {
            GTMFireProductImpressionList(p.products, p.name);
        });
    };

	var FireTealiumProductClick = function (productCategory, productSku, productName) {
	    utag.link({
	        "event_action": "Product Click",
	        "event_name": "product_click",
	        "event_category": "Ecommerce",
	        "event_label" : [productName],

	        "product_category": [productCategory],
	        "product_id": [productSku],
	        "product_name": [productName]
	    });
	};

    ACE.TagManager.FireTealiumLeadSubmission = function (newsletter,customerEmail) {
	    utag.link({
            "account_type": "Contact",
            "customer_email": customerEmail,
            "event_action": "EmailSignUp",
	        "event_category": "LeadCapture",
	        "event_label": newsletter,
	        "event_name": "lead_submission",

	        "lead_type": newsletter
	    });
	};

	ACE.TagManager.FireTealiumDownloadEvent = function (leadCaptureType,customerEmail,customerPhone) {
	    utag.link({
            "account_type": "Contact",
            "customer_email": customerEmail,
            "customer_phone": customerPhone,
            "event_action": "downloads",
	        "event_category": "form-download",
	        "event_label": leadCaptureType,
	        "event_name": "lead_submission",

	        "lead_type": leadCaptureType
	    });
	};

	ACE.TagManager.FireClientEvent = function (name, category, action, label, additionalData) {
		utag.link($.extend({},
			{
				"event_action": action || name,
				"event_category": category,
				"event_label": label,
				"event_name": name
			}, additionalData));
	};

	ACE.TagManager.ProductClicked = function (e) {
	    var $element = $(this);
	    var sku = $element.data('product_sku');
	    var category = $element.data('product_category');
	    var name = $element.data('product_name');

	    FireTealiumProductClick(category, sku, name);
	    GTMFireProductClick($element.data('product_clicklabel') || 'Product Click', sku);
	};

	ACE.TagManager.LaunchCourseEvent = function (e, tealiumData, courseData) {
	    if (typeof e === 'object' && e.button == 0 || e.button == 1) {
	        utag.link(tealiumData);

	        //call webAPI
	        $.ajax({
	            url: '/api/CourseLaunch',
	            type: 'POST',
	            contentType: "application/json; charset=utf-8",
	            data: JSON.stringify(courseData)
	        });
	    }
	};

	$('body').on('click', '[data-product_sku]', ACE.TagManager.ProductClicked);
	ACE.TagManager.LoadProductAttributeImpressions();
});